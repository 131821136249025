<template>
  <div class="ad-main-container">
    <!-- 筛选项 -->
    <div class="ad-main-header">
      <div>
        <a-input v-if="+currentLoginId === 25 || + currentLoginId === 488" placeholder="请输入包名" class="search_item" v-model.trim="query.bundle"></a-input>
        <m-select-more
          v-if="showApp"
          class="search_item"
          v-model="query.appIds"
          :allData="appList"
          :searchById="true"
          :hasIcon="true"
          :showId="true"
          label="应用"
          :showSelectedPart="true"
          :canSelectAll="true"
        />
        <a-select class="search_item" v-model="query.os" allowClear placeholder="请选择操作系统">
          <a-select-option v-for="item in operatingSystem" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select class="search_item" v-model="query.sts" allowClear placeholder="请选择应用状态">
          <a-select-option v-for="item in auditStsList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-range-picker
          class="search_items"
          v-if="+isAdmin !== 2"
          format="YYYY-MM-DD"
          :placeholder="['Start', 'End']"
          :value="rangePickerDate"
          @change="ChangeDate"
          :allowClear="false"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :disabledDate="disabledDate"
        >
        </a-range-picker>
        <a-button type="primary" class="btn" @click="handleSerach">查询</a-button>
      </div>
      <a-button type="primary" class="addAppBtn" @click="handleClickAdd">添加应用</a-button>
      <!-- <a-button type="primary" class="addAppBtn" @click="test">export</a-button> -->
    </div>
    <!-- table -->
    <div class="table">
      <a-table
        id="appTable"
        :locale="tablenodata"
        class="m_table_cust"
        :pagination="false"
        rowKey="id"
        :scroll="{ x: true }"
        :columns="asyncColumns"
        :data-source="dataList"
        :loading="loading"
        @change="handleSort"
        bordered
      >
        <div class="slot_place pointer" slot="place" slot-scope="name, row" @click="goAdplacen(row)">
          <div class="img">
            <img :src="row.iconUrl ? getCreativeUrl(row.iconUrl) : '/images/app-none.png'" alt="" />
          </div>
          <div class="desc">
            <span class="name">
              <div class="left">
                <IosSvg v-if="row.os === 0" class="svg-icon" />
                <AndroidSvg v-else class="svg-icon" />
                {{ row.name }}
              </div>
              <CloudSvg v-if="+row.apiSource === 2" class="svg-icon cloud-svg" />
            </span>
            <div class="id" style="font-size: 12px; line-height: 20px">
              ID:{{ row.id }}
              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>复制应用ID</span>
                </template>
                <CopySvg @click.stop="copyId(row.id)" style="cursor: pointer; margin-left: 5px"/>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div slot="sts" slot-scope="row">
          <a-popconfirm placement="right" :title="`确认${row.sts === 'A' ? '关闭' : '开启'}该应用?`" ok-text="是" cancel-text="否" @confirm="handleChangeSts(row.sts, row)">
            <a-switch size="small" default-checked :checked="row.sts === 'A'" />
          </a-popconfirm>
        </div>
        <!-- income -->
        <div class="income" slot="income" slot-scope="income, row">
          <div class="slot_incomes pointer">
            <div class="number">{{ numFormat(income, 3, '') }}</div>
            <div>
              <span class="propor-title">环比</span>
              <span
                class="propor-value"
                :class="{ increase: row.incomeRatio > 0, decrease: row.incomeRatio < 0 }"
              >{{ row.incomeRatio > 0 ? '+' + row.incomeRatio : '' + row.incomeRatio }}%</span
              >
            </div>
          </div>
          <a-spin :spinning="spinning">
            <div class="chart" :id="'echarts' + row.id"></div>
          </a-spin>
        </div>
        <div class="slot_income pointer" slot="dau" slot-scope="dau, row">
          <div class="number">{{ numFormat(Math.floor(dau / dauDays), 3, '') }}</div>
          <div>
            <span class="propor-title">环比</span>
            <span
              class="propor-value"
              :class="{ increase: row.dauRatio > 0, decrease: row.dauRatio < 0 }"
            >{{ row.dauRatio > 0 ? '+' + row.dauRatio : '' + row.dauRatio }}%</span
            >
          </div>
        </div>
        <!-- arpu -->
        <div class="slot_income pointer" slot="arpu" slot-scope="arpu, row">
          <div class="number">{{ numFormat(arpu, 5, '') }}</div>
          <div>
            <span class="propor-title">环比</span>
            <span
              class="propor-value"
              :class="{ increase: row.arpuRatio > 0, decrease: row.arpuRatio < 0 }"
            >{{ row.arpuRatio > 0 ? '+' + row.arpuRatio : '' + row.arpuRatio }}%</span
            >
          </div>
        </div>
        <!-- aipau -->
        <div class="slot_income pointer" slot="aipau" slot-scope="aipau, row">
          <div class="number">{{ numFormat(aipau, 3, '') }}</div>
          <div>
            <span class="propor-title">环比</span>
            <span
              class="propor-value"
              :class="{ increase: row.aipauRatio > 0, decrease: row.aipauRatio < 0 }"
            >{{ row.aipauRatio > 0 ? '+' + row.aipauRatio : '' + row.aipauRatio }}%</span
            >
          </div>
        </div>
        <!-- permeability -->
        <div class="slot_income pointer" slot="permeability" slot-scope="permeability, row">
          <div class="number">{{ numFormat(permeability, 3, '') }}%</div>
          <div>
            <span class="propor-title">环比</span>
            <span
              class="propor-value"
              :class="{ increase: row.permeabilityRatio > 0, decrease: row.permeabilityRatio < 0 }"
            >{{ row.permeabilityRatio > 0 ? '+' + row.permeabilityRatio : '' + row.permeabilityRatio }}%</span
            >
          </div>
        </div>
        <!-- requestFilledRate -->
        <div class="slot_income pointer" slot="requestFilledRate" slot-scope="requestFilledRate, row">
          <div class="number">{{ numFormat(requestFilledRate, 3, '') }}%</div>
          <div>
            <span class="propor-title">环比</span>
            <span
              class="propor-value"
              :class="{ increase: row.requestFilledRateRatio > 0, decrease: row.requestFilledRateRatio < 0 }"
            >{{
              row.requestFilledRateRatio > 0 ? '+' + row.requestFilledRateRatio : '' + row.requestFilledRateRatio
            }}%</span
            >
          </div>
        </div>
        <div class="pointer" slot="adPlaceNum" slot-scope="row" @click="goAdplacen(row)">{{ row.adPlaceNum }}个</div>
        <div class="slot_action" slot="action" slot-scope="row">
          <template v-if="+row.apiSource !== 2">
            <a-popover placement="bottom" :ref="`popover${row.id}`" overlayClassName="app_setting_popover">
              <GroupSvg class="link-btn" style="font-size: 18px; cursor: pointer" />
              <div class="popover-btn-box" slot="content">
                <div class="popover-btn" @click="handleClickUpdateApp(row)"><EditSvg class="icon"/>编辑</div>
                <div class="popover-btn" @click="handleClickDeleteBtn(row)"><DeleteSvg class="icon"/>删除</div>
                <div v-if="+row.isBind === 1 && isSuperAdmin" class="popover-btn" @click="handleAlert(row)"><ControlSvg class="icon"/>解绑</div>
                <div v-if="+row.isBind !== 1 && isSuperAdmin" class="popover-btn" @click="handleBindApp(row)"><StrategySvg class="icon"/>关联</div>
              </div>
            </a-popover>
          </template>
          <!-- <template v-else>--</template> -->
        </div>
      </a-table>
      <m-empty style="height: 70vh" v-if="dataList.length === 0">
        <template #description> 暂无应用！ </template>
      </m-empty>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
    </div>
    <AppModal
      v-if="addAppVisible"
      :appInfo="appInfo"
      :visible="addAppVisible"
      @modalCancel="addAppVisible = false"
      :isAdd="isAdd"
      @changeAppInfo="changeAppInfo"
    />

    <RelevanceModal
      :appInfo="appInfo"
      :visible="bindAppVisible"
      @modalCancel="bindAppVisible = false"
      @bindSuccess="getData"
    />
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="应用"
      @modalCancel="delVisible = false"
      @handleDelete="handleDelete"
    />
    <m-delete-modal
      :hasCancle="false"
      :isDelete="false"
      :delVisible="hintVisible"
      title="温馨提示"
      @modalCancel="hintVisible = false"
      @handleDelete="handleRemoveBind"
    >
      <template> 确认解除当前所绑定的应用？ </template>
    </m-delete-modal>
  </div>
</template>

<script>
import { appUpdate, getAppPager, getAllAppPage, appRemoveBind } from '@/api/media'
import AppModal from '../components/appModal'
import request from '@/utils/request'
import { auditStsList, operatingSystem, columnTitle, columns, column, rightColumns } from './list'
import { copy } from '@/utils/string'
import { mapState } from 'vuex'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import mixDate from '@/mixins/initDate'
import { getDuration, getDays } from '@/utils/duration'
import options from './options'
import { numFormat } from '@/utils/dealNumber'
import RelevanceModal from '../components/RelevanceModal'
import * as echarts from 'echarts'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import CopySvg from '@/assets/icons/copy.svg?inline'
import CloudSvg from '@/assets/icons/Could.svg?inline'
import EditSvg from '@/assets/icons/more/edit.svg?inline'
import DeleteSvg from '@/assets/icons/more/delete.svg?inline'
import ControlSvg from '@/assets/icons/more/control.svg?inline'
import StrategySvg from '@/assets/icons/more/share.svg?inline'
import GroupSvg from '@/assets/icons/more/Group.svg?inline'

export default {
  components: { AppModal, IosSvg, AndroidSvg, RelevanceModal, CopySvg, CloudSvg, GroupSvg, DeleteSvg, StrategySvg, EditSvg, ControlSvg },
  mixins: [mixDate],
  name: 'AdMain',
  data () {
    return {
      loading: false,
      showApp: false,
      columns,
      rightColumns,
      columnTitle,
      column, // 媒体角色登录展示列表
      dataList: [],
      query: {
        page: 1,
        limit: 10,
        appIds: [],
        sortBy: 1,
        sortOrder: 'desc',
        bundle: undefined
      },
      total: 0,
      tablenodata: {},
      auditStsList, // 应用状态下拉
      operatingSystem, // 操作系统下拉
      addAppVisible: false,
      appInfo: {},
      appList: [],
      spinning: false,
      options,
      rangePickerDate: null, // 日期
      dauDays: 0,
      bindAppVisible: false,
      delVisible: false,
      hintVisible: false // 关联弹窗
    }
  },
  watch: {
    // 路由传参
    $route: {
      handler (val) {
        if (val.query.appId) {
          this.query.appIds = [val.query.appId]
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      hasAdmin: (state) => state.user.hasAdmin,
      // 动态主路由
      allRoutes: (state) => state.permission.allRoutes,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      currentLoginId: (state) => state.user.id,
      isSuperAdmin: (state) => state.user.roles && state.user.roles[0] && state.user.roles[0] === 'SUPER_ADMIN'
    }),
    asyncColumns () {
      const columnTitleArr = this.columnTitle.map((item) => ({
        ...item,
        title: (
          <div>
            <m-tips showIcon={false} content={item.desc} title={item.title} width={150}></m-tips>
          </div>
        )
      }))
      let newColumns = [...columns]
      // 判断当前帐户是否有子账号
      if (!this.hasAdmin) {
        newColumns = newColumns.filter((item) => item.dataIndex !== 'userName')
      }
      if (this.isAdmin !== '2') {
        return [...newColumns, ...columnTitleArr, ...rightColumns]
      } else {
        return [...column, ...rightColumns]
      }
    }
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.query.endDate = endDate
    this.query.beginDate = startDate
    this.getAppList()
  },
  mounted () {
    this.getData()
  },
  methods: {
    handleAlert (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.appInfo = JSON.parse(JSON.stringify(row))
      this.hintVisible = true
    },
    handleClickDeleteBtn (row) {
      this.$refs[`popover${row.id}`].$children[0].sVisible = false
      this.appInfo = JSON.parse(JSON.stringify(row))
      this.delVisible = true
    },
    test () {
      if (this.dataList.length === 0) return
      const wb = XLSX.utils.table_to_book(document.querySelector('#appTable'))
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'sheet.xlsx')
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout
    },
    // 时间修改
    ChangeDate (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.query.beginDate = dateStart
      this.query.endDate = dateEnd
    },
    numFormat,
    getDays,
    // 列表字段排序
    handleSort (pagination, filters, sorter) {
      // 1=收益，2=dau,3=人均收益，4=展示/dau,5=渗透率，6=流量填充率
      const list = {
        income: 1,
        dau: 2,
        arpu: 3,
        aipau: 4,
        permeability: 5,
        requestFilledRate: 6
      }
      const status = {
        descend: 'desc', // 降序
        ascend: 'asc' // 升序
      }
      const { field, order } = sorter
      this.query.sortOrder = status[order]
      this.query.sortBy = list[field]
      this.getData()
    },
    // 初始化echart
    initChart (data) {
      this.$nextTick((_) => {
        setTimeout((_) => {
          data.forEach((item) => {
            if (item.incomeList.length > 1) {
              this.options.series[0].showSymbol = false
            } else {
              this.options.series[0].showSymbol = true
            }
            this.options.xAxis.data = item.incomeList.map((item) => item.date)
            this.options.series[0].data = item.incomeList.map((item) => item.income)
            const mychart = echarts.init(document.getElementById(`${'echarts' + item.id}`), null, { render: 'svg' })
            mychart.setOption(this.options)
            this.spinning = false
          })
        }, 400)
      })
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 1).end
    },
    // 获取应用下拉
    async getAppList () {
      const resp = await getAllAppPage()
      this.appList = resp.data[0] || []
      this.showApp = true
    },
    // 点击name 跳转到广告组
    goAdplacen (row) {
      if (!this.allRoutes.includes('adplace')) return
      this.$router.push({
        path: `/media/adplace?target=adplacen&appId=${row.id}`
      })
    },
    // 搜索
    handleSerach () {
      this.query.page = 1
      this.getData()
    },
    // 处理应用图标
    getCreativeUrl (url) {
      if (url) {
        return this.resourceUrl + url
      } else {
        return null
      }
    },
    changeAppInfo () {
      this.query.page = 1
      this.getAppList()
      this.getData()
    },
    // 点击新增应用
    handleClickAdd () {
      this.isAdd = true
      this.addAppVisible = true
    },
    // 复制
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 点击列表编辑
    handleClickUpdateApp (data) {
      this.$refs[`popover${data.id}`].$children[0].sVisible = false
      this.isAdd = false
      this.appInfo = data
      this.addAppVisible = true
    },
    // 获取列表
    async getData () {
      this.loading = true
      if (this.query.beginDate) {
        this.dauDays = this.getDays(this.query.beginDate, this.query.endDate)
      }
      const resp = await getAppPager(this.query)
      if (resp.code === 200) {
        this.dataList = resp.data.items || []
        this.total = resp.data.total
        this.loading = false
        this.spinning = true
        if (this.isAdmin !== '2' && resp.data.items) this.initChart(resp.data.items)
      }
    },
    // 开关
    async handleChangeSts (sts, row) {
      this.loading = true
      const resp = await appUpdate({
        ...row,
        sts: sts === 'S' ? 'A' : 'S'
      })
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getData()
      }
    },
    // 删除
    handleDelete () {
      request.get('/app?id=' + this.appInfo.id).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.getAppList()
          this.getData()
        }
      })
    },
    async handleRemoveBind () {
      const { code = 200 } = await appRemoveBind({
        // appId: this.appInfo.id
      })
      if (code === 200) {
        this.$message.success(`应用：${this.appInfo.name},解绑成功！`)
        this.getData()
      }
    },
    handleBindApp (row) {
      this.appInfo = JSON.parse(JSON.stringify(row))
      this.bindAppVisible = true
      this.appInfo = JSON.parse(JSON.stringify(row))
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getData()
    },
    handleChangePage (page) {
      this.query.page = page
      this.getData()
    },
    // 点击添加广告位
    handleClickAddPlace () {
      this.addPlaceVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.ad-main-container {
  position: relative;
  border-radius: 5px;
  min-height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 10px;
  .ad-header-container {
    padding: 0;
  }
  .ad-main-header {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    justify-content: space-between;

    .btn {
      // background: fade(@primary-color, 10%);
      // border: 1px solid @primary-color;
      // color: @primary-color;
      margin-left: 10px;
    }

    .search_item {
      padding: 0 5px;
      margin-bottom: 5px;
      width: 200px;
      display: inline-block;
    }
    .search_items {
      width: 230px;
    }
    .ad {
      padding: 0 5px;
      margin-left: 600px;
      width: 120px;
      display: inline-block;
    }
  }
  .m_table_cust {
    padding: 0;
  }
  .table {
    flex-grow: 1;
    background: #fff;
    min-height: calc(100vh - 235px);
    .pointer {
      cursor: pointer;
    }
    .slot_place {
      display: flex;
      align-items: center;
      .img {
        width: 40px;
        height: 40px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .desc {
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        .name {
          font-size: 14px;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 160px;
          .left{
            width: calc(100% - 20px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .svg-icon {
            margin-right: 3px;
            height: 13px;
            width: 13px;
          }
          .cloud-svg{
            width: 12px;
            height: 12px;
            transform: translateX(5px);
          }
        }
        .id {
          flex-grow: 1;
          line-height: 14px;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .id {
        font-size: 13px;
        line-height: 20px;
        color: #999;
      }
    }
    .income {
      display: flex;
      flex-direction: row;
      .chart {
        width: 80px;
        height: 40px;
      }
    }
    .slot_income,
    .slot_incomes {
      display: flex;
      flex-direction: column;

      .propor-title {
        font-size: 12px;
        margin-right: 3px;
        line-height: 20px;
        color: #a1a1a1;
        display: inline-block;
        transform: scale(0.83);
      }
      .propor-value {
        display: inline-block;
        font-size: 12px;
        transform: scale(0.83);
      }
      .increase {
        color: #e34949;
      }
      .decrease {
        color: #00943e;
      }
      .Ratio {
        margin-left: 10px;
      }
    }
    .slot_incomes {
      width: 48%;
    }
    .slot_action {
      span {
        font-size: 14px;
        margin-right: 10px;
      }
      span.btn {
        color: @primary-color;
        cursor: pointer;
      }
      span.line {
        color: #ccc;
      }
    }
  }
  .pagination {
    margin: 20px 0;
  }
}
::v-deep {
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: fade(@primary-color, 10%);
  }
  .m_table_cust table tbody tr:first-child {
    color: #999999;
    background: #fafafa;
  }

}
.popover-btn-box{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  .popover-btn{
    text-align: center;
    cursor: pointer;
    padding: 5px 0;
    display: flex;
    align-items: center;
    .icon{
      color: #fff;
      margin-right: 6px;
    }
  }
}
</style>

<style lang="less">
.app_setting_popover{
  .ant-popover-inner-content{
    padding: 6px 2px;
  }
}
</style>
