import { render, staticRenderFns } from "./addAccountManage.vue?vue&type=template&id=cb5a3336&scoped=true"
import script from "./addAccountManage.vue?vue&type=script&lang=js"
export * from "./addAccountManage.vue?vue&type=script&lang=js"
import style0 from "./addAccountManage.vue?vue&type=style&index=0&id=cb5a3336&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5a3336",
  null
  
)

export default component.exports