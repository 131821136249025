<template>
  <div class="ad-group-form-container">
    <a-form-model ref="formquery" :model="formquery" v-bind="layout">
      <!-- 同步规则 -->
      <a-form-model-item class="item_box" v-if="Number(isAdmin) === 2">
        <h4>
          数据同步规则
          <a-popover trigger="hover">
            <template #content>
              <p>说明：</p>
              <p>自动同步时不需要人工参与，只需设置好结算规则，系统次日按照结算规则生成数据同步给媒体账号</p>
              <p>人工同步则是人为对数据进行处理，当数据状态为发布时，才将数据同步给媒体账号</p>
            </template>
            <a-icon style="fon-size: 12px; cursor: pointer; padding: 0 5px" type="question-circle" />
          </a-popover>
        </h4>
        <div class="dataSynchron">
          <div class="dateBox">数据同步方式</div>
          <a-radio-group v-model="formquery.settlementRuleVo.ruleType">
            <a-radio value="1">自动</a-radio>
            <a-radio value="2">人工</a-radio>
          </a-radio-group>
        </div>
      </a-form-model-item>
      <!-- 报表设置 -->
      <a-form-model-item class="item_box" v-if="Number(isAdmin) === 2">
        <h4>筛选维度</h4>
        <div style="padding: 10px 0">
          <a-checkbox-group v-model="formquery.mediaReportRuleVo.selector">
            <span class="filterItemsBox" v-for="attr in filterItems" :key="attr.key">
              <a-checkbox :disabled="attr.disabled" :value="attr.id">{{ attr.title }}</a-checkbox>
            </span>
          </a-checkbox-group>
        </div>
        <h4 style="padding-top: 10px">自定义列表</h4>
        <div class="cust-tags-container">
          <div class="cust-tag-left">
            <div class="left-main">
              <div class="left-main-tags">
                <el-checkbox
                  :indeterminate="bascindeterminate"
                  v-model="basccheckAll"
                  @change="handleCheckAllBasc"
                  class="cust-tag-right-header"
                >
                  数据维度
                  <span style="margin-left: 5px">{{ isbascArr.length + '/' + basicAttributes.length }}</span>
                </el-checkbox>
                <div class="checkbox">
                  <div class="checkbox_item" v-for="(attr, indexAttr) in basicAttributes" :label="attr" :key="attr.id">
                    <el-checkbox
                      @change="handleCheckBas(attr, indexAttr)"
                      :disabled="attr.disabled"
                      v-model="attr.checked"
                    >{{ attr.title }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
              <div class="left-main-tags">
                <el-checkbox
                  :indeterminate="disindeterminate"
                  v-model="discheckAll"
                  @change="handleCheckAllDis(discheckAll)"
                  class="cust-tag-right-header"
                >
                  数据指标
                  <span style="margin-left: 5px">{{ isbascArrdis.length + '/' + disAttributes.length }}</span>
                </el-checkbox>
                <div class="checkbox">
                  <div class="checkbox_item" v-for="(attr, idex) in disAttributes" :label="attr" :key="attr.id">
                    <el-checkbox
                      @change="handleCheckBas(attr, idex)"
                      :disabled="attr.disabled"
                      v-model="attr.checked"
                    >{{ attr.title }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 已选字段 -->
          <div class="cust-tag-right">
            <div class="cust-tag-right-header">
              <span>已选字段（{{ dragArr.length }}）</span>
            </div>
            <m-vuedraggable v-model="dragArr" animation="350" chosen-class="chosen" class="drag_box">
              <transition-group>
                <div v-for="(e) in dragArr" :key="e.dataIndex" class="drag_item">
                  <span class="drag_text">{{ e.title }}</span>
                  <span class="el-icon-sort" />
                </div>
              </transition-group>
            </m-vuedraggable>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item class="item_box" v-if="allCheckedPlat">
        <span slot="label">广告平台
          <a-popover trigger="hover">
            <template #content>媒体账户只能看到已经选择的平台</template>
            <a-icon style="fon-size: 12px; cursor: pointer; padding: 0 5px" type="question-circle" />
          </a-popover>
        </span>
        <m-select-more
          style="width: 400px"
          :showLabel="false"
          v-model="formquery.mediaReportRuleVo.seePlat"
          :allData="platList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          width="450px"
          label="广告平台"
        />
      </a-form-model-item>
      <a-form-model-item class="item_box" v-if="allCheckedPlat">
        <span slot="label">自定义平台名称
          <a-popover trigger="hover">
            <template #content>未显示的广告平台产生的数据将以自定义名称汇总显示在媒体账户</template>
            <a-icon style="fon-size: 12px; cursor: pointer; padding: 0 5px" type="question-circle" />
          </a-popover>
        </span>
        <a-input
          style="width: 400px"
          v-model.trim="formquery.mediaReportRuleVo.userDefinedName"
          placeholder="请输入自定义名称"
          allowClear
        ></a-input>
      </a-form-model-item>
      <!-- 域名logo设置 -->
      <a-form-model-item class="item_box" v-if="Number(isAdmin) === 1">
        <h4 style="padding: 5px 0">
          域名logo设置
          <a-popover trigger="hover">
            <template #content>
              <p>域名LOGO配置说明：</p>
              <p>STEP1：域名需要在阿里云上进行备案；</p>
              <p>STEP2：域名需要解析至Mediatom平台方IP地址，IP地址请联系平台获取；</p>
              <p>STEP3：域名在平台上传成功后，不会立即生效，需要联系Mediatom平台方进行配置后方可生效</p>
            </template>
            <a-icon style="fon-size: 12px; cursor: pointer; padding: 0 5px" type="question-circle" />
          </a-popover>
        </h4>
        <!-- 平台名称 -->
        <div class="form_item_box">
          <span class="form_item_label">平台名称</span>
          <a-form-model-item>
            <a-input
              style="width: 400px"
              v-model.trim="formquery.autoWebVo.headerName"
              placeholder="请输入平台名称"
              allowClear
            ></a-input>
          </a-form-model-item>
        </div>
        <!-- 域名 -->
        <div class="form_item_box">
          <span class="form_item_label">域名</span>
          <a-form-model-item>
            <a-input
              style="width: 400px"
              v-model.trim="formquery.autoWebVo.domainName"
              placeholder="请输入域名"
              allowClear
            ></a-input>
          </a-form-model-item>
        </div>
        <!-- LOGO-ICON -->
        <div class="form_item_box">
          <span class="form_item_label">LOGO</span>
          <a-form-model-item prop="headerIcon">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              @change="headerIcon"
              :headers="header"
            >
              <img
                :key="formquery.autoWebVo.headerIcon"
                v-if="formquery.autoWebVo.headerIcon"
                :src="resourceUrl + formquery.autoWebVo.headerIcon"
                style="width: 120px; height: 100%"
              />
              <div v-else>
                <a-icon style="fontsize: 30px" :type="iconloading ? 'loading' : 'cloud-upload'" />
              </div>
            </a-upload>
            <span class="picture_card">推荐尺寸为1:1的方形图片，格式为png、jpg、jpeg</span>
          </a-form-model-item>
        </div>
        <!-- LOGO-登录 -->
        <div class="form_item_box">
          <span class="form_item_label">LOGO-登录</span>
          <a-form-model-item prop="loginIcon">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              @change="loginIcon"
              :headers="header"
            >
              <img
                :key="formquery.autoWebVo.loginIcon"
                v-if="formquery.autoWebVo.loginIcon"
                :src="resourceUrl + formquery.autoWebVo.loginIcon"
                style="width: 120px; height: 100%"
              />
              <div v-else>
                <a-icon style="fontsize: 30px" :type="iconloading1 ? 'loading' : 'cloud-upload'" />
              </div>
            </a-upload>
            <span class="picture_card">推荐尺寸为200x50的图片，格式为png、jpg、jpeg</span>
          </a-form-model-item>
        </div>
        <!-- LOGO-菜单 -->
        <div class="form_item_box">
          <span class="form_item_label">LOGO-菜单</span>
          <a-form-model-item prop="indexIcon">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              @change="indexIcon"
              :headers="header"
            >
              <img
                v-if="formquery.autoWebVo.indexIcon"
                :key="formquery.autoWebVo.indexIcon"
                :src="resourceUrl + formquery.autoWebVo.indexIcon"
                style="width: 120px; height: 100%"
              />
              <div v-else>
                <a-icon style="fontsize: 30px" :type="iconloading2 ? 'loading' : 'cloud-upload'" />
              </div>
            </a-upload>
            <span class="picture_card">推荐尺寸为200x50的图片，格式为png、jpg、jpeg</span>
          </a-form-model-item>
        </div>
        <!-- 登录图 -->
        <div class="form_item_box">
          <span class="form_item_label">登录图</span>
          <a-form-model-item prop="loginImage">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              @change="loginImage"
              :headers="header"
            >
              <img
                v-if="formquery.autoWebVo.loginImage"
                :key="formquery.autoWebVo.loginImage"
                :src="resourceUrl + formquery.autoWebVo.loginImage"
                style="width: 120px; height: 100%"
              />
              <div v-else>
                <a-icon style="fontsize: 30px" :type="iconloading3 ? 'loading' : 'cloud-upload'" />
              </div>
            </a-upload>
            <span class="picture_card">推荐尺寸为150x100的图片，格式为png、jpg、jpeg</span>
          </a-form-model-item>
        </div>
        <!-- 登录背景图 -->
        <div class="form_item_box">
          <span class="form_item_label">登录背景图</span>
          <a-form-model-item prop="loginBackgroundImage">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              @change="loginBackgroundImage"
              :headers="header"
            >
              <img
                v-if="formquery.autoWebVo.loginBackgroundImage"
                :key="formquery.autoWebVo.loginBackgroundImage"
                :src="resourceUrl + formquery.autoWebVo.loginBackgroundImage"
                style="width: 120px; height: 100%"
              />
              <div v-else>
                <a-icon style="fontsize: 30px" :type="iconloading4 ? 'loading' : 'cloud-upload'" />
              </div>
            </a-upload>
            <span class="picture_card">推荐尺寸为2880x1874的图片，格式为png、jpg、jpeg</span>
          </a-form-model-item>
        </div>
        <!-- 登录背景图（移动端） -->
        <div class="form_item_box">
          <span class="form_item_label">登录背景图（移动端）</span>
          <a-form-model-item prop="mobileBackgroundImage">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              @change="mobileBackgroundImage"
              :headers="header"
            >
              <img
                v-if="formquery.autoWebVo.mobileBackgroundImage"
                :key="formquery.autoWebVo.mobileBackgroundImage"
                :src="resourceUrl + formquery.autoWebVo.mobileBackgroundImage"
                style="width: 120px; height: 100%"
              />
              <div v-else>
                <a-icon style="fontsize: 30px" :type="iconloading5 ? 'loading' : 'cloud-upload'" />
              </div>
            </a-upload>
            <span class="picture_card">推荐尺寸为1500x3248的图片，格式为png、jpg、jpeg</span>
          </a-form-model-item>
        </div>
        <InvoiceForm v-model="formquery"/>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getAccountCustomByUserId, accountCustomUpdate, accountCustomAdd } from '@/api/system'
import { platformLinkList } from '@/api/aggregate'
import { filterItems, basicAttributes, disAttributes, sortLists } from './list'
import mixDate from '@/mixins/initDate'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { mapState } from 'vuex'
import InvoiceForm from './InvoiceForm'
export const settlementOption = [
  { name: '固定价格', value: '1' },
  { name: 'CPS分成', value: '2' }
]
export default {
  name: 'AdAccount',
  mixins: [mixDate],
  components: { IosSvg, AndroidSvg, InvoiceForm },
  inject: ['changeLoading', 'selectPlanInfo'],
  data () {
    return {
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      formquery: {

        userId: '',
        // 结算维度
        settlementRuleVo: {
          settlementDimension: '1',
          ruleType: '1',
          settlementType: undefined
        },
        // 域名logo对象
        autoWebVo: {
          headerName: undefined,
          domainName: undefined,
          headerIcon: undefined,
          loginIcon: undefined,
          indexIcon: undefined,
          loginImage: undefined,
          loginBackgroundImage: undefined,
          mobileBackgroundImage: undefined
        },
        // 报表设置
        mediaReportRuleVo: {
          userDefinedName: undefined,
          seePlat: [],
          selector: ['1', '2']
        },
        sysInvoiceInfoVo: {}
      },
      settlementType: undefined,
      basccheckAll: [],
      discheckAll: [],
      filterItems: filterItems,
      basicAttributes: basicAttributes,
      disAttributes: disAttributes,
      bascindeterminate: [],
      disindeterminate: [],
      dragArr: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/common/upload',
      iconloading: false,
      iconloading1: false,
      iconloading2: false,
      iconloading3: false,
      iconloading4: false,
      iconloading5: false,
      isAdmin: 1,
      appResponseVoList: [],
      placeList: [],
      platList: [],
      idx: 1,
      ifCheck: false,
      settlementOptions: settlementOption
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.isAdd = val.query.type === 'add'
        this.formquery.userId = val.query.userId
      },
      immediate: true,
      deep: true
    },
    basicAttributes: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.basccheckAll = arr.length === val.length
        this.bascindeterminate = arr.length > 0 && arr.length < val.length
        this.isbascArr = arr
      },
      deep: true,
      immediate: true
    },
    disAttributes: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.discheckAll = arr.length === val.length
        this.disindeterminate = arr.length > 0 && arr.length < val.length
        this.isbascArrdis = arr
      },
      deep: true,
      immediate: true
    },
    isAdmin: {
      handler (val) {
        if (Number(val) === 1) {
          // 管理员账号 账号定制结算维度只有广告平台
          this.formquery.settlementRuleVo.settlementDimension = '3'
          this.settlementOptions = settlementOption.filter((item) => item.value === '2')
        } else {
          // 媒体账号 编辑时如果已经选择 其他选项隐藏
          // 选中广告平台
          this.formquery.settlementRuleVo.settlementDimension = this.ifCheck
            ? this.formquery.settlementRuleVo.settlementDimension
            : '1'
          this.settlementOptions =
            this.formquery.settlementRuleVo.settlementDimension === '3'
              ? settlementOption.filter((item) => item.value === '2')
              : settlementOption
        }
      }
    }
  },
  async created () {
    // const { endDate } = this.initDate(0, 0)
    // this.formquery.settlementRuleVo.dateStart = endDate
    // 初始化已选字段
    this.dragArr = [...this.isbascArr, ...this.isbascArrdis]

    // 获取广告平台
    await this.getPlatList()
    // 通过id获取所用账号信息
    await this.getSelecInfo(this.$route.query.userId)
  },
  computed: {
    allCheckedPlat () {
      return this.formquery.mediaReportRuleVo.selector.includes('5') && this.basicAttributes.find((item) => item.id === '5')?.checked
    },
    ...mapState({
      role: (state) => state.user.roles[0],
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    hasSelectAppid () {
      const hasSelect = {}
      this.appResponseVoList.forEach((it) => {
        if (it.id !== undefined) hasSelect[it.id] = false
      })
      return hasSelect
    },
    hasSelectPlatid () {
      const hasSelect = {}
      this.platList.forEach((it) => {
        if (it.id !== undefined) hasSelect[it.id] = false
      })
      return hasSelect
    }
  },
  methods: {
    disabledDate (current) {
      return current && current > this.initDate(1, 0).end
    },
    // 获取广告平台
    async getPlatList () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        const arr = resp.data || []
        this.platList = arr?.filter((item) => item.id !== 1019).map((item) => ({ name: item.name, id: '' + item.id }))
      }
    },
    // 结算方式
    changeSettlementOptions (index) {
      this.settlementType = index
      this.formquery.settlementRuleVo.settlementType = index
    },
    // 获取定制账号信息
    async getSelecInfo (id) {
      const res = await getAccountCustomByUserId({ id })
      if (res.code === 200) {
        this.personInfo = res.data
        this.selectPlanInfo(res.data.user)
        this.isAdmin = res.data.user.isAdmin
        this.appResponseVoList = res.data.user?.appResponseVoList
        // 编辑时处理回显字段
        const { autoWeb = {}, mediaReportRule = {}, settlementRule = {}, sysInvoiceInfoVo = {} } = JSON.parse(JSON.stringify(res.data))
        // settlementRule
        if (!this.isAdd && Object.keys(settlementRule).length > 0) {
          this.formquery.settlementRuleVo = settlementRule
          this.formquery.settlementRuleVo.settlementDimension = settlementRule.settlementDimension
          this.formquery.settlementRuleVo.settlementType = settlementRule.settlementType
          this.settlementType = settlementRule.settlementType
        }
        // autoWeb
        if (!this.isAdd && Object.keys(autoWeb).length > 0) {
          this.formquery.autoWebVo = autoWeb
        }
        // mediaReportRule
        if (!this.isAdd && Object.keys(mediaReportRule).length > 0) {
          this.formquery.mediaReportRuleVo = mediaReportRule
          // 自定义列表字段回显选中效果
          this.disAttributes = this.disAttributes.map((obj) => ({
            ...obj,
            checked: mediaReportRule?.data?.some((item) => item === obj.id)
          }))
          this.basicAttributes = this.basicAttributes.map((obj) => ({
            ...obj,
            checked: mediaReportRule?.dimension?.some((item) => item === obj.id)
          }))
          const arr = [...this.disAttributes, ...this.basicAttributes].filter((item) => item.checked)
          // 有排序字段
          const sort = mediaReportRule?.sort && mediaReportRule?.sort?.length > 0
          if (!sort) return
          // 已选字段根据上次存储顺序排序
          this.dragArr = sort && sortLists(arr, mediaReportRule.sort)
        }
        // sysInvoiceInfoVo
        if (!this.isAdd && res.data.sysInvoiceInfoVo) {
          this.formquery.sysInvoiceInfoVo = sysInvoiceInfoVo
        }
      }
    },
    // 结算维度单位
    company () {
      let string = ''
      const settlementType = this.formquery.settlementRuleVo.settlementType
      const settlementDimension = this.formquery.settlementRuleVo.settlementDimension
      if (settlementDimension === '1' && settlementType === '1') {
        string = '元/DAU'
      } else if (settlementDimension === '1' && settlementType === '2') {
        string = '%'
      } else if (settlementDimension === '2' && settlementType === '1') {
        string = '元/千次曝光'
      } else if (settlementDimension === '2' && settlementType === '2') {
        string = '%'
      } else if (settlementDimension === '3' && settlementType === '2') {
        string = '%'
      }
      return string
    },
    // 自定义上传方法
    iconUrlValidator (rule, value, callback, index) {
      if (value) {
        return true
      } else {
        return callback(new Error('请上传头像'))
      }
    },
    // 上传
    headerIcon (info) {
      if (info.file.status === 'uploading') {
        this.iconloading = true
        return
      }
      if (info.file.status === 'done') {
        this.formquery.autoWebVo.headerIcon = info.file.response.data
        this.iconloading = false
      }
    },
    loginIcon (info) {
      if (info.file.status === 'uploading') {
        this.iconloading1 = true
        return
      }
      if (info.file.status === 'done') {
        this.formquery.autoWebVo.loginIcon = info.file.response.data
        this.iconloading1 = false
      }
    },
    indexIcon (info) {
      if (info.file.status === 'uploading') {
        this.iconloading2 = true
        return
      }
      if (info.file.status === 'done') {
        this.formquery.autoWebVo.indexIcon = info.file.response.data
        this.iconloading2 = false
      }
    },
    loginImage (info) {
      if (info.file.status === 'uploading') {
        this.iconloading3 = true
        return
      }
      if (info.file.status === 'done') {
        this.formquery.autoWebVo.loginImage = info.file.response.data
        this.iconloading3 = false
      }
    },
    loginBackgroundImage (info) {
      if (info.file.status === 'uploading') {
        this.iconloading4 = true
        return
      }
      if (info.file.status === 'done') {
        this.formquery.autoWebVo.loginBackgroundImage = info.file.response.data
        this.iconloading4 = false
      }
    },
    mobileBackgroundImage  (info) {
      if (info.file.status === 'uploading') {
        this.iconloading5 = true
        return
      }
      if (info.file.status === 'done') {
        this.formquery.autoWebVo.mobileBackgroundImage = info.file.response.data
        this.iconloading5 = false
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      }
      const isLt1000 = file.size / 1024 < 1000
      if (!isLt1000) {
        this.$message.error('文件大小不能超过1000kb!')
      }
      return isJpgOrPng && isLt1000
    },
    // 点击基础属性全选
    handleCheckAllBasc (val) {
      this.bascindeterminate = val
      const arr = this.basicAttributes.filter((item) => !item.disabled)
      this.basicAttributes.map((item) => {
        if (item.disabled) return
        item.checked = val
      })
      this.dragArr = val
        ? [...this.dragArr, ...arr]
        : this.dragArr.filter((item) => !arr.some((items) => item.key === items.key))
    },
    // 点击属性展示全选
    handleCheckAllDis (val) {
      this.disindeterminate = val
      const arr = this.disAttributes.filter((item) => !item.disabled)
      this.disAttributes.map((item) => {
        if (item.disabled) return
        item.checked = val
      })
      this.dragArr = val
        ? [...this.dragArr, ...arr]
        : this.dragArr.filter((item) => !arr.some((items) => item.key === items.key))
    },
    // 点击选中/取消属性
    handleCheckBas (val, i) {
      var idx = this.dragArr.findIndex((item) => item.key === val.key)
      val.checked ? this.dragArr.push(val) : this.dragArr.splice(idx, 1)
    },
    // 切换结算维度按钮
    changeRadio (e) {
      // e.target.value  1=应用，2=广告位，3=广告平台
      this.appResponseVoList.map((item, index) => {
        item.checked = false
      })
      this.platList.map((item, index) => {
        item.checked = false
      })
      this.formquery.settlementRuleVo.settlementDimension = e.target.value
      this.settlementType = undefined
      this.formquery.settlementRuleVo.settlementType = undefined
      this.settlementOptions =
        e.target.value === '3' ? settlementOption.filter((item) => item.value === '2') : settlementOption
    },
    // 自定义列表字段选中状态清除、恢复初始化
    getCheckall (arr) {
      arr.map((item) => {
        item.checked = item.key === 'date' || item.key === 'stsDesc'
      })
    },
    // 保存
    handleSubmitAdGroup () {
      return new Promise((resolve) => {
        this.$refs.formquery.validate(async (valid) => {
          if (valid) {
            // 媒体账号
            if (Number(this.isAdmin) === 2) {
              const obj = this.formquery.mediaReportRuleVo
              // 数据维度
              obj.dimension = this.isbascArr.map((item) => item.id)
              // 数据指标
              obj.data = this.isbascArrdis.map((item) => item.id)
              // 排序数组
              obj.sort = this.dragArr.map((item) => item.dataIndex)
              delete this.formquery.autoWebVo
            } else {
              // 管理员账号
              delete this.formquery.mediaReportRuleVo
              if (!Object.keys(this.formquery.autoWebVo).length > 0) delete this.formquery.autoWebVo
            }
            if (this.isAdd) {
              this.changeLoading(true)
              const resp = await accountCustomAdd(this.formquery)
              resp.code === 200 && resolve(resp)
              this.getCheckall(this.disAttributes)
              this.getCheckall(this.basicAttributes)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            } else {
              this.changeLoading(true)
              const resp = await accountCustomUpdate(this.formquery)
              this.getCheckall(this.disAttributes)
              this.getCheckall(this.basicAttributes)
              resolve(resp)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.ad-group-form-container {
  .form_item {
    padding-left: 10px;
    margin-bottom: 10px;
    .form_item_label {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  .form_item_box {
    display: flex;
    padding: 5px 0;
    .form_item_label {
      margin-right: 20px;
      width: 100px;
    }
  }
  .picture_card {
    border-radius: 5px;
    padding: 25px 30px;
    background: #f2f2f2;
    color: #a6a6a6;
    font-size: 12px;
  }
  .weight_label {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
    padding: 10px;
    margin: 10px 0 10px 0;
  }
  .item_box {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    margin-bottom: 15px;
    h4 {
      margin-bottom: 0;
    }
  }
  .dataSynchron {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 10px;
    color: #4d4d4d;
    .dateBox {
      width: 140px;
    }
  }
  .btnBox {
    color: #ee539c;
    border: 1px solid #f7b5d4;
    background: #fdebf3;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .btnBoxs {
    color: #5982ff;
    border: 1px solid #cedaff;
    background: #eaefff;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 2px;
  }
  .filterItemsBox {
    border: 1px solid #f0f0f0;
    padding: 6px 12px;
    border-radius: 6px;
    background: #fafafa;
    margin-right: 16px;
  }
  .cust-tags-container {
    border-radius: 5px;
    display: flex;
    padding: 15px 0;
    .cust-tag-left,
    .cust-tag-right {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      height: 350px;
      overflow-y: auto;
    }
    .cust-tag-left {
      width: 90%;
      border-radius: 5px;
      margin-right: 10px;
      border: 0px solid #fff;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .left-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 20px;
        .left-main-tags {
          border: 1px solid #f2f2f2;
          margin-bottom: 20px;
          border-radius: 5px;
          .checkbox {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 10px 0;
            padding: 0 10px;
            .el-checkbox {
              margin-right: 0;
            }
            .checkbox_item {
              width: 20%;
              padding: 0 10px;
              height: 40px;
              input {
                font-size: 12px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                vertical-align: middle;
                border-radius: 5px !important;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .cust-tag-right {
      width: calc(30% - 10px);
      border-radius: 5px;
      border: 1px solid #f2f2f2;
      .drag_box {
        flex: 1;
        background-color: #fff !important;
        overflow: auto;
        height: 100%;
        padding: 10px 0;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
      }
      .drag_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 92%;
        height: 35px;
        margin: 0 auto;
        padding: 0 10px;
        background-color: #fff;
        margin-top: 5px;
        cursor: move;
        .drag_text {
          flex: 1;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
        .el-icon-sort {
          font-size: 18px;
          width: 42px;
          text-align: center;
          color: @primary-color;
        }
      }
    }
  }
  .cust-tag-right-header {
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    width: 100%;
    background: #fafafa;
    border-bottom: 1px solid #f2f2f2;
    padding: 0 20px;
    border-radius: 5px 5px 0px 0px;
  }
}
::v-deep.ad-group-form-container {
  .aRadioBtn();
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4d4d4d;
  }
  .el-checkbox__label {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
  }
  .ant-checkbox-inner {
    height: 15px;
    width: 15px;
  }
  .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked {
    font-size: 12px;
    color: #fff;
    background: @primary-color;
  }
  .ant-radio-group.ant-radio-group-outline label {
    color: #333;
  }
  .radioIsadmin {
    border-radius: 5px !important;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 0px;
  }
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 10px;
    border: 1px solid #ebebef;
    background: #fbfbfd;
  }
}
</style>
