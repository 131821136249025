<template>
  <div class="priority-item-container">
    <!-- 折叠 -->
    <template v-if="record.type === 'expend'">
      <span @click="handleExpend" style="margin-right: 5px;" class="expend">
        <a-icon :type="isExpend ? 'up-circle' : 'down-circle'" />
        {{ isExpend ? '展开' : '折叠' }}
      </span>
      <span>{{ record.text }}</span>
    </template>
    <!-- 汇总 -->
    <span v-else-if="record.type === 'all'">汇总</span>
    <!-- 竞价 -->
    <div title="竞价" v-else-if="record.settleType || record.type === 'sdk'">
      <C2SSvg v-if="+record.settleType === 2" style="width: 25px;margin-top:5px;"/>
      <S2SSvg v-else style="width: 25px;margin-top:5px" />
    </div>
    <!-- 兜底 -->
    <div title="兜底" v-else-if="record.type === 'bottoms'">
      <BottomSvg style="width: 13px; height: 13px; color: rgb(255, 68, 0)" />
    </div>
    <!-- 普通固价广告位（开启） -->
    <span v-else-if="!record.settleType && record.sts === 'A'">{{ record.priority }}</span>
    <!-- 普通固价广告位（关闭） -->
    <span v-else-if="!record.settleType && record.sts === 'S'">-</span>
  </div>
</template>

<script>
// import BiddingSvg from '@/assets/icons/bidding.svg?inline'
import BottomSvg from '@/assets/icons/bottom.svg?inline'
import C2SSvg from '../svgs/C2S.svg?inline'
import S2SSvg from '../svgs/S2S.svg?inline'
export default {
  components: { C2SSvg, S2SSvg, BottomSvg },
  props: {
    record: {
      default: () => ({}),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    isExpend: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    handleExpend () {
      this.$emit('handleExpend')
    }
  }
}
</script>

<style lang="less" scoped>
  .priority-item-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .expend{
      color: @primary-color;
      cursor: pointer;
    }
  }
</style>
