<template>
  <div class="advanced-container">
    <a-page-header @back="goback" style="cursor: pointer;">
      <template>
        <span @click="goback" slot="title">返回</span>
        <span @click="goback" slot="title" class="line"></span>
        <span @click="goback" slot="title">聚合管理</span>
      </template>
      <a-button
        v-if="origin === 'https://app.mediatom.cn'"
        style="width: 200px; margin-right: 10px"
        type="link"
        target="_blank"
        href="https://app.mediatom.cn/docs/#/?blogid=129"
      >了解更多瀑布流使用建议</a-button
      >
    </a-page-header>
    <div class="info_panel">
      <div class="panel_item app">
        <div class="item_box">
          <span class="label">应用</span>
          <div class="value">
            <img :src="Info.appIcon ? resourceUrl + Info.appIcon : '/images/app-none.png'" alt="" />
            <div style="display: flex; flex-direction: column">
              <span>
                <IosSvg v-if="Info.os === '0'" style="width: 14px; height: 14px" />
                <AndroidSvg v-if="Info.os === '1'" style="width: 14px; height: 14px" />
                {{ appName }}
              </span>
              <span>ID：{{ Info.appId }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="panel_item place">
        <div class="item_box">
          <span class="label">广告位</span>
          <span class="value">{{ placeName }}</span>
        </div>
      </div>
      <div class="panel_item position">
        <div class="item_box">
          <span class="label">广告类型</span>
          <span class="value">{{ positionName }}</span>
        </div>
      </div>
    </div>
    <div class="advanced_table">
      <a-tabs v-model="currentGroupId" @change="changeGroup" style="max-width: 800px; margin-left: 2%">
        <a-tab-pane v-for="item in groupList" :key="item.id" :tab="item.name"></a-tab-pane>
      </a-tabs>
      <a-table
        style="width: 96%; margin: 0 auto"
        :rowKey="(record, index) => index"
        :data-source="currentGroupInfo.abTests || []"
        :pagination="false"
        :scroll="{ x: true }"
        :key="currentGroupId"
        :customRow="customRow"
        bordered
      >
        <a-table-column align="left" dataIndex="groupName" title="A/B测试分组名称" width="200px">
          <template slot-scope="groupName, record">
            {{ groupName }}&nbsp;&nbsp;<span v-if="record.type === 0">{{ record.proportion }}%</span>
            <span v-if="record.type === 1">{{ getProportion(record) + '%' }}</span>
            <i v-show="record.showIcon" style="margin-left: 10px" class="el-icon-edit" @click="editName(record)"></i>
          </template>
        </a-table-column>
        <!-- <a-table-column align="left" dataIndex="quickRequest" title="并行类型" width="120px">
          <template slot-scope="text, record">
            <a-select v-model="record.quickRequest" @change="settingArgs(record)" style="width: 120px">
              <a-select-option value="S">并行请求数</a-select-option>
              <a-select-option value="A">快速请求</a-select-option>
            </a-select>
          </template>
        </a-table-column> -->
        <!-- 并行请求数 -->
        <a-table-column align="" dataIndex="concurrentNum" width="140px">
          <m-tips
            slot="title"
            :content="`并行请求数为1表示串行请求，若条数为n（n>1)，${title}同时向前n个广告源发起请求。如果未填充，会继续向n+1到第2n个广告源发起请求。`"
            title="并行请求数"
            :width="150"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <div class="concurrentNum">
              <a-input-number
                v-model.number.trim="record.concurrentNum"
                addonAfter="个"
                :min="1"
                :max="20"
                @change="inputConcurrentNum($event, index)"
                @blur="settingArgs(record)"
              />
              <div class="unit">个</div>
            </div>
          </template>
        </a-table-column>
        <!-- 串行层数 -->
        <a-table-column align="left" dataIndex="fixedAdsourceCount" width="100px">
          <m-tips
            slot="title"
            :content="`依据并行请求数和瀑布流中广告源配置个数，计算出当前瀑布流需要串行请求的次数。请求发起时间${title} SDK向瀑布流发起请求间隔时间。`"
            title="串行层数"
            :width="150"
            :showIcon="false"
          ></m-tips>

          <template slot-scope="text, record">
            {{ record.concurrentNum > 0 ? getNumber(record) : 0 }}
          </template>
        </a-table-column>

        <!-- 单次并行请求超时时长 -->
        <a-table-column align="left" dataIndex="timeout" width="180px">
          <m-tips
            slot="title"
            content="一次并行请求没有返回广告填充结果(成功或失败)时最多等待的时长"
            title="单次并行请求超时时长"
            :width="150"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <div class="concurrentNum">
              <a-input-number
                @change="inputTimeout($event, index)"
                v-model.number.trim="record.timeout"
                @blur="settingArgs(record)"
                :min="1"
                :max="10"
                :step="0.1"
              >
              </a-input-number>
              <div class="unit">s</div>
            </div>
          </template>
        </a-table-column>
        <!-- 瀑布流请求总超时时长 -->
        <a-table-column align="left" dataIndex="allTime" width="180px">
          <m-tips
            slot="title"
            content="一次瀑布流总请求还没有返回任何成功广告填充时最多等待的时长"
            title="瀑布流请求总超时时长"
            :width="150"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <div class="concurrentNum">
              <a-input-number
                v-model.number.trim="record.allTime"
                @change="inputAllTime($event, index)"
                @blur="settingArgs(record)"
                :min="1"
                :max="60"
                :step="1"
              >
              </a-input-number>
              <div class="unit">s</div>
            </div>
          </template>
        </a-table-column>
        <!-- 服务端竞价请求超时时长 -->
        <a-table-column align="left" dataIndex="apiTimeout" width="200px">
          <m-tips
            slot="title"
            content="服务端竞价广告源请求广告超时等待时长"
            title="服务端竞价请求超时时长"
            :showIcon="false"
            :width="150"
          ></m-tips>
          <template slot-scope="text, record">
            <div class="concurrentNum">
              <a-input-number
                :max="10"
                :min="1"
                :step="0.1"
                v-model.number.trim="record.apiTimeout"
                @blur="settingArgs(record)"
              >
              </a-input-number>
              <div class="unit">s</div>
            </div>
          </template>
        </a-table-column>
        <!-- 客户端竞价请求超时时长 -->
        <a-table-column align="left" dataIndex="clientTimeout" width="200px">
          <m-tips
            slot="title"
            content="客户端竞价广告源请求广告超时等待时长"
            title="客户端竞价请求超时时长"
            :showIcon="false"
            :width="150"
          ></m-tips>
          <template slot-scope="text, record">
            <div class="concurrentNum">
              <a-input-number
                :min="1"
                :max="10"
                :step="0.1"
                v-model.number.trim="record.clientTimeout"
                @blur="settingArgs(record)"
              >
              </a-input-number>
              <div class="unit">s</div>
            </div>
          </template>
        </a-table-column>
        <!-- 请求发起时间 -->
        <a-table-column align="left" dataIndex="requestInterval" width="180px">
          <m-tips
            slot="title"
            :content="`${title} SDK向瀑布流发起请求间隔时间。`"
            title="请求发起时间"
            :showIcon="false"
            :width="150"
          ></m-tips>
          <template slot-scope="text, record">
            <div class="concurrentNum">
              <a-input-number
                v-model.number.trim="record.requestInterval"
                @blur="settingArgs(record)"
                :min="0.1"
                :max="10"
                :step="0.1"
                @input="inputRequestInterval($event, record)"
              >
              </a-input-number>
              <div class="unit">s</div>
            </div>
          </template>
        </a-table-column>
        <!-- 请求发起时间 -->
        <a-table-column v-if="+positionId === 3" align="left" dataIndex="refreshNum" title="广告刷新次数" width="180px">
          <!-- <m-tips
            slot="title"
            :content="`${title} SDK向瀑布流发起请求间隔时间。`"
            title="请求发起时间"
            :showIcon="false"
            :width="150"
          ></m-tips> -->
          <template slot-scope="text, record">
            <div class="concurrentNum">
              <a-input-number
                v-model.number.trim="record.refreshNum"
                @blur="settingArgs(record)"
                :min="0"
                :step="1"
                @input="inputRefreshNum($event, record)"
              >
              </a-input-number>
              <div class="unit">次</div>
            </div>
          </template>
        </a-table-column>
        <!-- 缓存状态 -->
        <a-table-column align="left" dataIndex="cacheSts" width="120px">
          <m-tips
            slot="title"
            :content="`缓存功能仅对${title}SDK 2.4.8 及以上版本生效，支持插屏、激励视频、原生和全屏广告`"
            title="缓存状态"
            :width="150"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <a-switch :checked="record.cacheSts === 'A'" @change="changeCacheSts($event, index)" />
          </template>
        </a-table-column>
        <!-- 动态瀑布流 -->
        <a-table-column v-if="role === 'SUPER_ADMIN'" align="left" dataIndex="autoOperation" width="120px">
          <m-tips
            slot="title"
            :content="`开启动态瀑布流，${title}平台将会自动帮助您优化瀑布流的配置。`"
            title="动态瀑布流"
            :width="150"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <a-switch :checked="record.autoOperation === 'A'" @change="changeAutoOperation($event, index)" />
          </template>
        </a-table-column>
        <!-- 缓存时间 -->
        <a-table-column align="left" dataIndex="cacheTime" width="180px">
          <m-tips
            slot="title"
            :content="`广告源缓存的有效时间，各家联盟平台缓存有效时长不同，${title}平台限制最长可缓存30min。`"
            title="缓存时间"
            :width="150"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <div class="concurrentNum" v-if="record.cacheSts === 'A'">
              <a-input-number
                v-model.number.trim="record.cacheTime"
                @change="inputCacheTime($event, index)"
                @blur="settingArgs(record)"
                :max="30"
                :min="5"
                :step="1"
              ></a-input-number>
              <div class="unit min">min</div>
            </div>
            <span v-else>--</span>
          </template>
        </a-table-column>
        <!-- 优化目标 -->
        <a-table-column align="left" dataIndex="enhanceTarget" width="120px">
          <m-tips
            slot="title"
            :content="`从${title} SDK V2.6.3 开始支持。\n\r1.优化展示率：以优先展示广告为目标，一定程度上会降低广告收益，用户等待广告时间较短，用户体验较好；\n\r2.优化eCPM：以优化广告展示价格为目标，用户等待广告时间较长，影响用户体验；
            以上两种优化目标，请依据自身场景谨慎选择，也可使用AB测试找出最优的策略。`"
            title="优化目标"
            :width="200"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record">
            <a-select
              v-model="record.enhanceTarget"
              @change="settingArgs(record, 'change')"
              style="width: 120px"
            >
              <a-select-option :value="1">展示率</a-select-option>
              <a-select-option :value="2">eCPM</a-select-option>
            </a-select>
          </template>
        </a-table-column>
        <!-- 缓存数量 -->
        <a-table-column align="left" dataIndex="cacheQueueLength" width="180px">
          <m-tips
            slot="title"
            :content="`SDK保留缓存广告的总个数`"
            title="缓存数量"
            :width="200"
            :showIcon="false"
          ></m-tips>
          <template slot-scope="text, record, index">
            <div class="concurrentNum">
              <a-input-number
                @change="inputTimeout($event, index)"
                :min="1"
                :max="5"
                type="number"
                step="1"
                v-model.number="record.cacheQueueLength"
                @blur="settingArgs(record)"
              ></a-input-number>
              <div class="unit">个</div>
            </div>
          </template>
        </a-table-column>
      </a-table>
    </div>
    <a-modal
      v-model="popconfirmVisible"
      title="提示"
      :confirm-loading="confirmLoading"
      @ok="handSure"
      :width="600"
      dialogClass="abModal"
    >
      <a-form-model ref="ruleForm" class="batchModel" :model="formquery" v-bind="layout">
        <a-form-model-item
          label="A/B测试分组名称"
          prop="groupName"
          :rules="[{ required: true, message: '请输入A/B测试分组名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入A/B测试分组名称" v-model.trim="formquery.groupName"> </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getPositionList } from '@/api/common'
import { mapState } from 'vuex'
import { getGroupIdListByPlace, updateArgs } from '@/api/aggregate'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { isObjectValueEqual } from '@/utils/util'
export default {
  components: { IosSvg, AndroidSvg },
  data () {
    return {
      currentGroupId: '',
      currentGroupInfo: {},
      query: {
        appId: '',
        placeId: ''
      },
      groupList: [],
      positionId: 0,
      appName: '--',
      placeName: '--',
      positionList: [],
      popconfirmVisible: false,
      confirmLoading: false,
      formquery: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      Info: {},
      nameEdit: false
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      origin: (state) => state.autoweb.origin,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    positionName () {
      const positionInfo = this.positionList.find((item) => item.id === this.positionId)
      return positionInfo ? positionInfo.name : '--'
    }
  },
  async created () {
    this.query.appId = this.$route.query.appId
    this.query.placeId = this.$route.query.placeId
    this.positionId = +this.$route.query.positionId
    await this.getGroupInfo()
    this.currentGroupId = this.groupList[0].id
    this.changeGroup(this.currentGroupId)
    this.getPositionList()
  },
  methods: {
    // 修改测试分组名称弹窗确认
    async handSure () {
      this.settingArgs(this.formquery)
      this.nameEdit = true
    },
    // 编辑测试分组名称
    editName (data) {
      this.popconfirmVisible = true
      this.formquery = JSON.parse(JSON.stringify(data))
    },
    // 获取显示层列数
    getNumber (data) {
      let number = 0
      if (data.fixedAdsourceCount % data.concurrentNum === 0) {
        number = data.fixedAdsourceCount / data.concurrentNum
      } else {
        number = (data.fixedAdsourceCount - (data.fixedAdsourceCount % data.concurrentNum)) / data.concurrentNum + 1
      }
      return number
    },
    // table行方法
    customRow (record, index) {
      return {
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            record.showIcon = true
          },
          // 移除 清除id 还原样式
          mouseleave: (event) => {
            record.showIcon = false
          }
        }
      }
    },
    changeGroup (e) {
      this.currentGroupId = e
      this.currentGroupInfo = JSON.parse(JSON.stringify(this.groupList.find((item) => item.id === this.currentGroupId)))
    },
    async getGroupInfo () {
      const resp = await getGroupIdListByPlace(this.query.placeId)
      if (resp.code === 200) {
        const groupList = resp.data.groups || []
        groupList.forEach((item) => {
          item.abTests.forEach((child) => {
            child.showIcon = false
            child.timeout = child.timeout / 1000
            child.allTime = child.allTime / 1000
            child.apiTimeout = child.apiTimeout / 1000
            child.clientTimeout = child.clientTimeout / 1000
            child.requestInterval = child.requestInterval / 1000
          })
        })
        this.Info = resp.data
        this.groupList = groupList
        this.appName = resp.data.appName || ''
        this.placeName = resp.data.placeName || ''
        this.nameEdit && this.changeGroup(this.currentGroupId)
      }
    },
    goback () {
      this.$router.push({
        name: 'config',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId
        }
      })
    },
    async getPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },
    inputCacheTime (e, index) {},
    inputAllTime (e, index) {},
    inputTimeout (e, index) {},
    inputConcurrentNum (e, index) {
      if (!+e) {
        this.currentGroupInfo.abTests[index].concurrentNum = 1
      }
    },
    inputRefreshNum (e, record) {},
    inputRequestInterval (e, record) {
    },
    async settingArgs (record, type) {
      const { abTests } = this.groupList.find((item) => item.id === this.currentGroupId)
      const testId = record.testId
      const tempRecord = abTests.find((item) => item.testId === testId)
      // 判断数据是否发生改动， 未改的则停止
      if (isObjectValueEqual(tempRecord, record)) {
        return
      }
      if (record.requestInterval > record.timeout) {
        this.$message.warning('单次并行请求超时时长不得小于请求发起时间！')
        record.timeout = tempRecord.timeout
        record.requestInterval = tempRecord.requestInterval
        return
      }
      const settingquery = {
        ...record
      }
      settingquery.timeout = settingquery.timeout * 1000
      settingquery.allTime = settingquery.allTime * 1000
      settingquery.apiTimeout = settingquery.apiTimeout * 1000
      settingquery.clientTimeout = settingquery.clientTimeout * 1000
      settingquery.requestInterval = settingquery.requestInterval * 1000
      // settingquery.cacheTime = settingquery.cacheTime
      const resp = await updateArgs(settingquery)
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getGroupInfo()
        this.popconfirmVisible = false
      }
    },
    changeCacheSts (e, index) {
      this.currentGroupInfo.abTests[index].cacheSts = e ? 'A' : 'S'
      this.settingArgs(this.currentGroupInfo.abTests[index], 'change')
    },
    changeAutoOperation (e, index) {
      this.currentGroupInfo.abTests[index].autoOperation = e ? 'A' : 'S'
      this.settingArgs(this.currentGroupInfo.abTests[index], 'change')
    },
    getProportion (item) {
      if (item.sts === 'A') {
        let proportion = 0
        item.intervalList.forEach((child) => {
          const p = child[1] - child[0] + 1
          proportion += p
        })
        return proportion
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="less" scoped>
.advanced-container {
  margin: -55px 0 10px;
  ::v-deep {
    .ant-page-header {
      background: #fff;
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-page-header-back,
      .ant-page-header-heading-title {
        font-size: 14px;
      }
      .ant-page-header-content {
        padding-top: 10px;
      }
      .line {
        margin: 0 10px;
        background-color: gray;
        width: 2px;
        height: 12px;
        display: inline-block;
      }
    }
  }
  .info_panel {
    margin: 10px 10px 0;
    background: #fff;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    .panel_item {
      display: flex;
      width: 20%;
      min-width: 200px;
      display: flex;
      justify-content: flex-start;
      padding-left: 15px;
      .item_box {
        .label {
          font-size: 15px;
          font-weight: 600;
          color: #000;
          width: 100px;
        }
        .value {
          margin-top: 8px;
          font-size: 13px;
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 10px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  .advanced_table {
    background: #fff;
    border-radius: 5px;
    margin: 10px 10px 0;
    padding: 20px 0;
    min-height: 66vh;
    .concurrentNum {
      display: flex;
      .ant-input-number {
        height: 36px;
        line-height: 36px;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
      }
      .unit {
        height: 36px;
        border: 1px solid #d9d9d9;
        background-color: #fafafa;
        width: 30px;
        border-radius: 2px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 14px;
        transition: all 0.3s;
        text-align: center;
        padding: 0 11px;
        border-left: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &.min{
          width: 70px;
        }
      }
    }
    ::v-deep {
      .ant-table-tbody {
        tr {
          td {
            padding: 12px 16px;
          }
        }
      }
    }
  }
}
.abModal {
  .batchModel {
    padding: 30px;
  }
}
</style>
