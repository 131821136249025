var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-container"},[_c('a-form-model',{ref:"registerForm",attrs:{"model":_vm.form,"rules":{}}},[_c('a-form-model-item',{attrs:{"rules":[
        {
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        },
        {
          message: '邮箱必须为邮箱格式',
          trigger: 'blur',
          pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        }
      ],"prop":"loginId"}},[_c('a-input',{attrs:{"type":"text","placeholder":"请输入注册邮箱"},model:{value:(_vm.form.loginId),callback:function ($$v) {_vm.$set(_vm.form, "loginId", $$v)},expression:"form.loginId"}})],1),_c('a-form-model-item',{attrs:{"rules":[{ required: true, trigger: 'blur', validator: _vm.validatePass }],"prop":"password"}},[_c('a-input',{ref:"password",attrs:{"type":_vm.isShow ? '' : 'password',"autocomplete":"new-password","placeholder":"请输入登录密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.password"}},[(_vm.isShow)?_c('AllowSvg',{staticClass:"svg-icon",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.changeIsShow.apply(null, arguments)}},slot:"suffix"}):_c('NoallowSvg',{staticClass:"svg-icon",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.changeIsShow.apply(null, arguments)}},slot:"suffix"})],1)],1),_c('a-form-model-item',{attrs:{"rules":[
        { required: true, trigger: 'blur', validator: _vm.validateConfirmPass },
        { required: true, message: '请输入确认密码', trigger: 'blur' }
      ],"prop":"confirmPassword"}},[_c('a-input',{ref:"confirmPassword",attrs:{"type":_vm.isShowConfirm ? '' : 'password',"placeholder":"请输入确认密码","autocomplete":"new-password"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.confirmPassword"}},[(_vm.isShowConfirm)?_c('AllowSvg',{staticClass:"svg-icon",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.changeIsShowConfirm.apply(null, arguments)}},slot:"suffix"}):_c('NoallowSvg',{staticClass:"svg-icon",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.changeIsShowConfirm.apply(null, arguments)}},slot:"suffix"})],1)],1),_c('a-form-model-item',{attrs:{"prop":"company","rules":[{ required: true, message: '请输入公司名称', trigger: 'blur' }]}},[_c('a-input',{attrs:{"type":"text","placeholder":"请输入公司名称"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1),(_vm.host !== 'adx.admergex.com')?_c('a-form-model-item',{attrs:{"prop":"mobile","rules":[
        {
          required: true,
          message: '请输入正确的手机号',
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        }
      ]}},[_c('a-input',{attrs:{"type":"text","placeholder":"请输入手机号码"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1):_vm._e(),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","block":""},on:{"click":_vm.handleSubmit}},[_vm._v("注册")])],1),_c('div',{staticClass:"bottom-box"},[_c('a',{on:{"click":_vm.skipToLogin}},[_vm._v("使用已有账号登录"),_c('a-icon',{attrs:{"type":"right"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }