/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-12-03 12:08:08
 * @FilePath: /mediatom-web/src/mixins/initDate.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import moment from 'moment'
import momentTz from 'moment-timezone'
import store from '@/store'

export const AreaTimeZoneMap = {
  '+08:00': 'Asia/Shanghai', // 上海 东八区
  '-08:00': 'America/Los_Angeles', // 美国洛杉矶 西八区
  '+00:00': 'Europe/London' // 伦敦 零时区
}

export default {
  data () {
    return {
      rangePickerDate: ['', '']
    }
  },
  created () {
    const userTimeZone = store.state.user.timeZone
    const hasAdmin = store.state.user.hasAdmin
    const isAdmin = store.state.user.isAdmin
    const role = store.state.user.roles[0]
    const cur = store.state.user.cur
    if (hasAdmin && +cur === 1 && (isAdmin === 1 || role === 'SUPER_ADMIN')) {
      momentTz.tz.setDefault(AreaTimeZoneMap['+00:00'])
    } else {
      momentTz.tz.setDefault(AreaTimeZoneMap[userTimeZone])
    }
  },
  methods: {
    moment,
    initDate (startDays, endDays, format = 'YYYY-MM-DD') {
      const beginDay = new Date().getTime()
      // const date = moment.tz(beginDay, 'Asia/London')
      // console.log(date)
      // 今天
      const end = momentTz(new Date(beginDay - 1000 * 60 * 60 * 24 * endDays))
      // 一星期前
      const start = momentTz(new Date(beginDay - 1000 * 60 * 60 * 24 * startDays))
      const endDate = end.format(format)
      const startDate = start.format(format)
      // this.rangePickerDate = [start, end]
      return {
        end,
        start,
        endDate, // string
        startDate // string
      }
    },
    initMonth (startMonths, endMonths, format = 'YYYY-MM-DD') {
      const nowMonth = new Date()
      const MonthNum = nowMonth.getMonth()
      const end = moment(nowMonth.setMonth(MonthNum - endMonths))
      const start = moment(nowMonth.setMonth(MonthNum - startMonths))
      const endMonth = end.format(format)
      const startMonth = start.format(format)
      return {
        end,
        start,
        endMonth, // string
        startMonth // string
      }
    },
    // 获取上个月月初和月底到现在的天数的moment对象
    getLastMonthDuration () {
      // 获取当前日期时间
      const currentDate = moment()
      // 获取上个月的第一天和最后一天
      const firstDayOfLastMonth = currentDate.clone().subtract(1, 'months').startOf('month')
      const lastDayOfLastMonth = currentDate.clone().subtract(1, 'months').endOf('month')
      return [firstDayOfLastMonth, lastDayOfLastMonth]
    }
  }
}
