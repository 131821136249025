import EditSvg from '@/assets/icons/edit.svg?inline'
import DeleteSvg from '@/assets/icons/delete.svg?inline'
import ControlSvg from '@/assets/icons/actionIcons/control.svg?inline'
import ProfitSettingSvg from '@/assets/icons/actionIcons/profitSetting.svg?inline'
import QPSSvg from '@/assets/icons/actionIcons/QPS.svg?inline'
import WatchDataSvg from '@/assets/icons/actionIcons/watchData.svg?inline'
import SettingDetailSvg from '@/assets/icons/actionIcons/settingDetail.svg?inline'
import GroupSvg from '@/assets/icons/actionIcons/group.svg?inline'
import CopyPublicPlaceSvg from '@/assets/icons/actionIcons/copyPublicPlace.svg?inline'

export default {
  components: { EditSvg, DeleteSvg, ControlSvg, ProfitSettingSvg, QPSSvg, WatchDataSvg, SettingDetailSvg, GroupSvg, CopyPublicPlaceSvg },
  data () {
    return {
    }
  },
  methods: {
  }
}
