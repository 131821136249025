<template>
  <div class="pricing-strategy-container">
    <!-- 竞价底价策略 -->
    <a-form-model-item prop="bidfloorStrategy">
      <m-tips
        slot="label"
        :content="
          `竞价底价：当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。` +
            `</br>` +
            `策略：` +
            `</br>` +
            `1)媒体优先：以媒体请求时传入的底价为准。` +
            `</br>` +
            `(2)媒体底价百分比：对媒体传入的底价按百分比向上浮动。` +
            `</br>` +
            `(3)平台自定义：以平台设置的底价为准。` +
            `</br>` +
            `注意SDK接入，设置底价，需使用平台自定义。`
        "
        :title="'竞价底价策略'"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.bidfloorStrategy" size="medium" @change="changeSet">
        <el-radio label="0" border>媒体优先</el-radio>
        <el-radio label="1" border>媒体底价百分比</el-radio>
        <el-radio label="2" border>平台自定义</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <!-- 竞价底价 -->
    <a-form-model-item
      v-if="+formquery.bidfloorStrategy === 2"
      :rules="[{ required: true, validator: validatorBidfloor, trigger: 'blur' }]"
      prop="bidfloor"
    >
      <m-tips
        slot="label"
        :content="`RTB竞价广告源竞价底价：当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。`"
        :title="'竞价底价'"
        :width="200"
      ></m-tips>
      <a-input type="number" v-model.number="formquery.bidfloor" placeholder="请输入竞价底价" style="width: 220px">
        <span slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- 底价百分比值 -->
    <a-form-model-item
      label="底价百分比值"
      prop="bidfloorFloatingRate"
      v-if="+formquery.bidfloorStrategy === 1"
      :rules="[{ required: true, validator: validatorBidfloorFloatingRate, trigger: 'blur' }]"
    >
      <a-input type="number" v-model.number="formquery.bidfloorFloatingRate" placeholder="请输入底价百分比值" style="width: 220px">
        <span slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 媒体出价定制策略 -->
    <a-form-model-item prop="strategySts">
      <m-tips
        slot="label"
        content="该策略开启后，可以将广告响应的价格分区间处理，不同的价格区间给媒体返回不同的价格；出价策略有三种：透传、百分比浮动、平台自定义；如果广告响应的价格不在设置的区间内，则可通过【媒体出价策略】进行过滤处理。"
        :title="'媒体出价定制策略'"
        :width="250"
      ></m-tips>
      <a-switch size="small" :checked="formquery.strategySts === 'A'" @change="changeSts" />
      <a-button
        v-if="formquery.strategySts === 'A'"
        @click="handleAddStrategy"
        size="small"
        style="margin-left: 100px"
        type="primary"
      >
        添加策略
      </a-button>
    </a-form-model-item>
    <template v-for="(item, index) in formquery.priceStrategyList">
      <div class="strategy-item" v-if="formquery.strategySts === 'A'" :key="index">
        <a-form-model-item label="广告平台出价">
          <a-row type="flex" align="middle">
            <a-col :span="14">
              <a-form-model-item class="number-input-form">
                <div class="input-box">
                  <!-- <div class="number-input">
                    <el-input-number
                      class="input"
                      v-model="item.min"
                      controls-position="right"
                      placeholder="最小值"
                      :controls="false"
                      :min="0"
                      :max="item.max || 100000000"
                    ></el-input-number>
                    <div class="suffix">{{ moneyType }}</div>
                  </div> -->
                  <a-input :min="0" type="number" v-model.number="item.min" placeholder="最小值" style="width: 140px">
                    <span slot="suffix">{{ moneyType }}</span>
                  </a-input>
                  <div class="middle">-</div>
                  <!-- <div class="number-input">
                    <el-input-number
                      class="input"
                      placeholder="最大值"
                      controls-position="right"
                      :controls="false"
                      v-model="item.max"
                      :min="item.min || 0"
                    ></el-input-number>
                    <div class="suffix">{{ moneyType }}</div>
                  </div> -->
                  <a-input :min="item.min || 0" type="number" v-model.number="item.max" placeholder="最大值" style="width: 140px">
                    <span slot="suffix">{{ moneyType }}</span>
                  </a-input>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item class="number-input-form">
                <span @click="deleteStrategy(index)" class="delete-btn"><a-icon type="delete" /></span>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="媒体出价策略">
          <div class="strategy-box">
            <a-select
              v-model="item.type"
              style="width: 140px; margin-right: 10px; margin-top: 4px"
            >
              <a-select-option v-for="(child, i) in typeList" :key="i" :value="child.value">
                {{ child.label }}
              </a-select-option>
            </a-select>
            <!-- <div v-if="item.type === '2'" class="number-input">
              <a-input-number
                style="width: 200px"
                class="input"
                placeholder="固定底价"
                v-model.trim="item.value"
                :min="0"
              />
              <div class="suffix">{{ moneyType }}</div>
            </div> -->
            <div v-if="item.type === '1'" class="number-input">
              <a-input-number
                style="width: 200px"
                class="input"
                placeholder="请输入价格浮动"
                v-model.trim="item.value"
                :min="0"
              />
              <div class="suffix">%</div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="出家范围" v-if="item.type === '3'">
          <a-input type="number" v-model.number="item.minRange" placeholder="最小值" style="width: 120px">
          </a-input>
          <span style="margin: 0 5px">-</span>
          <a-input type="number" v-model.number="item.maxRange" placeholder="最大值" style="width: 120px">
          </a-input>
        </a-form-model-item>
      </div>
    </template>
    <!-- 媒体出价策略 -->
    <a-form-model-item prop="settleStrategy">
      <m-tips
        slot="label"
        :content="
          `媒体出价：广告填充时，给媒体返回的广告价格。` +
            `</br>` +
            `策略：` +
            `</br>` +
            `(1)默认：以广告响应的价格直接返回。` +
            `</br>` +
            `(2)百分比浮动：对广告响应的价格按百分比向下浮动后返回。` +
            `</br>` +
            `(3)平台自定义：以平台设置的价格返回。`
        "
        :title="'媒体出价策略'"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.settleStrategy" size="medium" @change="changeSet">
        <el-radio label="0" border>默认</el-radio>
        <el-radio label="1" border>百分比浮动</el-radio>
        <el-radio label="2" border>平台自定义</el-radio>
        <el-radio label="3" border>PMP</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <!-- 浮动比例 -->
    <a-form-model-item
      prop="floatingRate"
      v-if="+formquery.settleStrategy === 1"
      :rules="[{ required: true, validator: validatorFloatingRate, trigger: 'blur' }]"
    >
      <m-tips
        slot="label"
        :content="
          `浮动百分比不限范围，正数表示向下浮动以增加利润，负数则向上浮动可能导致亏损。请依据业务需求谨慎设定。`
        "
        :title="'价格浮动'"
        :width="250"
      ></m-tips>
      <!-- <div class="number-input">
        <el-input-number
          class="input"
          v-model="formquery.floatingRate"
          controls-position="right"
          placeholder="请输入价格浮动"
          :controls="false"
          :min="0"
        ></el-input-number>
        <div class="suffix">%</div>
      </div> -->
      <a-input type="number" v-model.number="formquery.floatingRate" placeholder="请输入价格浮动" style="width: 220px">
        <span slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 结算底价 -->
    <a-form-model-item
      label="结算底价"
      prop="settleBidfloor"
      :rules="[{ required: true, validator: validatorSettleBidfloor, trigger: 'blur' }]"
      v-if="+formquery.settleStrategy === 2 || +formquery.settleStrategy === 3"
    >
      <!-- <div class="number-input">
        <el-input-number
          class="input"
          v-model="formquery.settleBidfloor"
          controls-position="right"
          placeholder="请输入结算底价"
          :controls="false"
          :min="0"
        ></el-input-number>
        <div class="suffix">{{ moneyType }}</div>
      </div> -->
      <a-input type="number" v-model.number="formquery.settleBidfloor" placeholder="请输入结算底价" style="width: 220px">
        <span slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
    <!-- dealid -->
    <a-form-model-item
      label="dealid"
      prop="dealid"
      :rules="[{ required: true, message: '请输入dealid', trigger: 'blur' }]"
      v-if="+formquery.settleStrategy === 3"
    >
      <el-input
        style="width:220px"
        v-model="formquery.dealid"
        placeholder="请输入dealid"
      ></el-input>
    </a-form-model-item>
    <!-- 上游成交策略 -->
    <a-form-model-item prop="dspSettleStrategy">
      <m-tips
        slot="label"
        :content="
          `上游成交：广告展示时，上报的广告成交价格。` +
            `</br>` +
            `策略：` +
            `</br>` +
            `(1)默认：按媒体上报的广告价格直接成交。` +
            `</br>` +
            `(2)百分比浮动：对媒体上报的广告价格按百分比向上浮动后成交。` +
            `</br>` +
            `(3)一价成交：以广告响应的价格直接成交。`
        "
        :title="'上游成交策略'"
        :width="250"
      ></m-tips>
      <el-radio-group v-model="formquery.dspSettleStrategy" size="medium" @change="changeSet">
        <el-radio label="0" border>默认</el-radio>
        <el-radio label="1" border>百分比浮动</el-radio>
        <el-radio label="2" border>一价成交</el-radio>
        <el-radio label="3" border>平台自定义</el-radio>
        <el-radio label="4" border>一价下浮</el-radio>
      </el-radio-group>
    </a-form-model-item>
    <!-- 上游成交浮动比例 -->
    <a-form-model-item
      label="成交浮动比例"
      v-if="+formquery.dspSettleStrategy === 1 || +formquery.dspSettleStrategy === 4"
      prop="dspFloatingRate"
    >
      <!-- <div class="number-input">
        <el-input-number
          class="input"
          v-model="formquery.dspFloatingRate"
          controls-position="right"
          placeholder="请输入价格浮动"
          :controls="false"
          :min="0"
        ></el-input-number>
        <div class="suffix">%</div>
      </div> -->
      <a-input type="number" v-model.number="formquery.dspFloatingRate" placeholder="请输入价格浮动" style="width: 220px">
        <span slot="suffix">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 平台成交价 -->
    <a-form-model-item
      label="平台成交价"
      v-if="+formquery.dspSettleStrategy === 3"
      prop="dspSettleValue"
      :rules="[{ required: true, validator: validatorDspSettleValue, trigger: 'blur' }]"
    >
      <!-- <div class="number-input">
        <el-input-number
          class="input"
          v-model="formquery.dspSettleValue"
          controls-position="right"
          placeholder="请输入成交价"
          :controls="false"
          :min="0"
        ></el-input-number>
        <div class="suffix">{{ moneyType }}</div>
      </div> -->
      <a-input type="number" v-model.number="formquery.dspSettleValue" placeholder="请输入成交价" style="width: 220px">
        <span slot="suffix">{{ moneyType }}</span>
      </a-input>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      formqueryTemp: {
        bidfloorStrategy: '0',
        bidfloor: 0,
        bidfloorFloatingRate: 0,
        strategySts: 'S',
        priceStrategyList: [],
        settleStrategy: '0',
        floatingRate: 0,
        settleBidfloor: 0,
        dspSettleStrategy: '0',
        dspFloatingRate: 0,
        dspSettleValue: 0,
        dealid: undefined
      },
      typeList: [
        {
          label: '透传',
          value: '0'
        },
        {
          label: '百分比浮动',
          value: '1'
        },
        {
          label: '平台自定义',
          value: '2'
        },
        {
          label: '范围内随机',
          value: '3'
        }
      ]
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      sensitivity: (state) => state.asyncPermission.sensitivity,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  props: {
    query: {
      default: () => ({
        bidfloorStrategy: '0',
        bidfloor: 0,
        bidfloorFloatingRate: 0,
        strategySts: 'S',
        priceStrategyList: [],
        settleStrategy: '0',
        floatingRate: 0,
        settleBidfloor: 0,
        dspSettleStrategy: '0',
        dspFloatingRate: 0,
        dspSettleValue: 0,
        dealid: undefined
      }),
      type: Object
    }
  },
  methods: {
    changeSet (data) {
      this.clearValidate()
    },
    validatorBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入竞价底价！'))
      } else if (value < 0.01) {
        return callback(new Error('竞价底价必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorBidfloorFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入底价百分比值！'))
      } else if (value < 0.01) {
        return callback(new Error('底价百分比值必须大于0.01！'))
      } else if (value > 100) {
        return callback(new Error('底价百分比值必须小于100！'))
      } else {
        return callback()
      }
    },
    validatorFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入浮动比例！'))
      } else {
        return callback()
      }
    },
    validatorSettleBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入结算底价！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorDspFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入浮动比例！'))
      } else if (value < 1) {
        return callback(new Error('浮动比例必须大于等于1！'))
      } else if (value > 100) {
        return callback(new Error('浮动比例必须小于100！'))
      } else {
        return callback()
      }
    },
    validatorDspSettleValue (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入平台成交价！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    changeSts (e) {
      this.formquery.strategySts = e ? 'A' : 'S'
      if (e) {
        this.formquery.priceStrategyList = [
          {
            type: '0',
            value: undefined,
            min: undefined,
            max: undefined,
            minRange: undefined,
            maxRange: undefined
          }
        ]
      }
    },
    clearValidate () {
      this.$emit('clearValidate')
    },
    handleAddStrategy () {
      this.formquery.priceStrategyList.push({
        type: '0',
        value: undefined,
        min: undefined,
        max: undefined
      })
    },
    deleteStrategy (index) {
      this.formquery.priceStrategyList.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .el-radio.is-bordered {
    margin-right: 0px;
  }
  .el-radio.is-bordered.is-checked {
    background: fade(@primary-color, 10%);
    border-radius: 5px;
    border: 1px solid fade(@primary-color, 30%);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: @primary-color;
  }
  .el-radio__input {
    display: none;
  }
}
::v-deep.pricing-strategy-container .number-input-form.ant-row.ant-form-item {
  margin-bottom: 0;
}
::v-deep.pricing-strategy-container {
  .input input {
    height: 32px !important;
  }
  .el-input__inner {
    border-radius: 4px 0 0 4px;
  }
  .ant-select .ant-select-selection {
    height: 32px;
  }
  .ant-select:not(.ant-select-sm) .ant-select-selection__rendered {
    line-height: 32px;
  }
}
.pricing-strategy-container {
  .strategy-box {
    display: flex;
    align-items: center;
  }
  .input-box {
    display: flex;

    .middle {
      margin: 0 10px;
      display: inline-block;
    }
  }
  .delete-btn {
    cursor: pointer;
    color: red;
  }
  .number-input {
    display: flex;
    align-items: center;
    .input {
      width: 100px;
    }
    .suffix {
      display: inline-block;
      border-top: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      border-left: 0px;
      border-radius: 0 3px 3px 0;
      background-color: #fafafa;
      text-align: center;
      height: 32px;
      line-height: 32px;
      width: 40px;
    }
  }
}
</style>
