/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-16 18:23:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-12-03 12:08:35
 * @FilePath: /mediatom-web/src/utils/toTimer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { AreaTimeZoneMap } from '@/mixins/initDate'
import momentTz from 'moment-timezone'
import store from '@/store'
export const toDate = (n, type) => {
  const userTimeZone = store.state.user.timeZone
  const hasAdmin = store.state.user.hasAdmin
  const isAdmin = store.state.user.isAdmin
  const role = store.state.user.roles[0]
  const cur = store.state.user.cur
  if (hasAdmin && +cur === 1 && (isAdmin === 1 || role === 'SUPER_ADMIN')) {
    momentTz.tz.setDefault(AreaTimeZoneMap['+00:00'])
  } else {
    momentTz.tz.setDefault(AreaTimeZoneMap[userTimeZone])
  }
  const date = momentTz(new Date(n)).format(type || 'YYYY-MM-DD')
  return date
}
